import * as React from "react";
import { CollectionFlightsCarouselProps } from "./typings";
import { CollectionFlightsCarousel as RetailCollectionFlightsCarousel } from "@shared-ui/retail-incentives-collections";
import { withStores } from "src/stores";
import { observer } from "mobx-react";
import { UitkTabsBackGroundColorType } from "@egds/react-core/tabs";

export const CollectionFlightsCarousel = withStores("context")(
  observer((props: CollectionFlightsCarouselProps) => {
    const {
      context: { searchContext },
      templateComponent,
    } = props;
    const {
      collectionID,
      collectionItemsMaxPageSize,
      collectionGroupsPageSize,
      collectionItemsPerGroupPageSize,
      hideCollectionHeaderTitle,
      hideCollectionHeaderDescription,
      hideCollectionSummary,
      hideCollectionHeaderSubtitle,
      hideCollectionTermsAndConditions,
      tabBackground = UitkTabsBackGroundColorType.PRIMARY,
      displayFlightDealScoreBadge,
    } = templateComponent.config;

    const inputData = {
      collectionId: collectionID,
      pageContext: {
        propertySearch: { primary: { destination: { regionId: searchContext?.location?.id?.toString() }, rooms: [] } },
      },
      paginationContext: {
        maxPageSize: collectionItemsMaxPageSize,
      },
      collectionGroupsPageSize,
      collectionItemsPerGroupPageSize,
      displayFlightDealScoreBadge,
    };

    /* istanbul ignore next */
    const hideAttributes = {
      hideHeaderTitle: hideCollectionHeaderTitle ?? false,
      hideHeaderSubtitle: hideCollectionHeaderSubtitle ?? false,
      hideHeaderDescription: hideCollectionHeaderDescription ?? false,
      hideSummary: hideCollectionSummary ?? false,
      hideTermsAndConditions: hideCollectionTermsAndConditions ?? false,
    };

    return <RetailCollectionFlightsCarousel inputs={inputData} tabBackground={tabBackground} {...hideAttributes} />;
  })
);

export default CollectionFlightsCarousel;
